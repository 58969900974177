import flowers from '../images/flowers.png';
import { Grid, Card } from '@mantine/core';
import '../styles/App.css';
import '../fonts/MrsEavesSmallCapsRoman.ttf'
import classes from '../styles/Column.module.css'

function MainCard() {
  return (
    <Grid.Col
      span={{ base: 12, md: 12, lg: 4 }}
      order={{ base: 1, xs: 1, sm: 1, lg: 2 }}
    >
      <Card
        shadow="sm"
        radius="md"
        className={classes.MainCard}
        withBorder
      >
        <img src={flowers} className="Img-flowers" alt="Blomster" />
        <div className="MainCard-content">
          <p className="Txt-wedding">BRYLLUPET TIL</p>
          <tag className="Txt-names">Siri Helene</tag>
          <tag className="Txt-names"> og </tag>
          <tag className="Txt-names"> Andreas</tag>
          <br /><br />
          <p className="Txt-info-main-card">15. JUNI 2024</p>
          <p className="Txt-info-main-card">VIELSE I FJÆRE KIRKE</p>
          <p className="Txt-info-main-card">KL. 12:00</p>
        </div>
        <img src={flowers} className="Img-flowers-upside-down" alt="Blomster" />
      </Card>


    </Grid.Col>
  )
}

export default MainCard;