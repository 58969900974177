import '../styles/App.css';
import FlowersLeftCorner from '../images/flowers_left_corner.png';
import { Card } from '@mantine/core';
import { MobileView } from 'react-device-detect';

function AllergiesCard() {
    return (
        <Card
            shadow="sm"
            radius="md"
            className="Side-card"
            style={{ marginTop: "6px" }}
            withBorder
        >
            <MobileView>
                <img src={FlowersLeftCorner} className="Img-flower-right-corner-top" alt="Blomster" />
            </MobileView>
            <p className="Txt-title">Allergener</p>
            <p className="Txt-info">
                Dersom noen har allergier vi må vite om,
                er det fint om kokken Kjetil Haglund
                får beskjed på tlf: 995 09 866
            </p>
            <MobileView>
                <br/>
            </MobileView>
            <img src={FlowersLeftCorner} className="Img-flower-left-corner-bottom" alt="Blomster" />
        </Card>
    )
}

export default AllergiesCard