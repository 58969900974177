import '../styles/Toastmasters.css';
import FlowersLeftCorner from '../images/flowers_left_corner.png';
import { Card } from '@mantine/core';
import { MobileView } from 'react-device-detect';
import '../styles/App.css';
import '../fonts/MrsEavesSmallCapsRoman.ttf'

function ToastmasterCard() {
    return (
        <Card
            shadow="sm"
            radius="md"
            withBorder
            className="Side-card"
        >
            <img src={FlowersLeftCorner} className="Img-flower-left-corner-top" alt="Blomster" />
            <p className="Txt-title">Toastmastere</p>
            <p className="Txt-toastmaster-names">
                Johannes Morterud & Vetle Sælen
            </p>
            <p className="Txt-info">
                Dersom dere ønsker å tale, synge,
                ha en lek, eller showe på annet
                vis, er det bare å ta kontakt med 
                <br/>Vetle på tlf: 469 53 252
            </p>
            <MobileView>
                <img src={FlowersLeftCorner} className="Img-flower-right-corner-bottom" alt="Blomster" />
            </MobileView>
        </Card>
    )
}

export default ToastmasterCard;