import BlueFlowers from '../images/blue_flowers.jpeg';
import { Card, Grid } from '@mantine/core';
import '../styles/App.css';
import { BrowserView, MobileView } from 'react-device-detect';

function DirectionsCard() {
    return (
        <Card
            shadow="sm"
            radius="md"
            style={{ padding: "4px" }}
            withBorder
        >
            <BrowserView>
                <Grid align="center" justify="center">
                    <Grid.Col span={2}>
                        <img src={BlueFlowers} className="Blue-flowers" alt="Blomster" />
                    </Grid.Col>
                    <Grid.Col span={8} style={{ textAlign: "center", padding: "12px" }}>
                        <p className="Txt-title">Veibeskrivelse</p>
                        <div style={{ display: "inline-block", textAlign: "start" }}>
                            <ul className="Directions-list">
                                <li>Fra Fjære kirke kjører man E18 til Nedenes, og fortsetter i retning Fevik.</li>
                                <li>Rett etter Bunnpris Nedenes tar man til høyre, inn på FV177.</li>
                                <li>Her følger man veien i 200 meter, før man ser en låve på venstre side.</li>
                                <li>Pin: <a target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/f1aPTh9HBYSu1c75A">her</a></li>
                            </ul>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <img src={BlueFlowers} className="Blue-flowers" alt="Blomster" />
                    </Grid.Col>
                </Grid>
            </BrowserView>
            <MobileView style={{ textAlign: "center", padding: "12px" }}>
                <p className="Txt-title">Veibeskrivelse</p>
                <div style={{ display: "inline-block", textAlign: "start" }}>
                    <ul className="Directions-list">
                        <li>Fra Fjære kirke kjører man i retning Bunnpris Nedenes</li>
                        <li>Rett før Grubenåsen Bussholdeplass tar man til venstre, inn på FV177.</li>
                        <li>Her følger man veien i 200 meter, før man ser en låve på venstre side.</li>
                        <li>Pin: <a target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/f1aPTh9HBYSu1c75A">her</a></li>
                    </ul>
                </div>
            </MobileView>
        </Card >
    )
}

export default DirectionsCard;