import FlowersLeftCorner from '../images/flowers_left_corner.png';
import { Card } from '@mantine/core';
import { BrowserView, MobileView } from 'react-device-detect';
import '../styles/App.css';

function WishList() {
    return (
        <Card
            shadow="sm"
            radius="md"
            withBorder
            className="Side-card"
        >
            <BrowserView>
                <img src={FlowersLeftCorner} className="Img-flower-right-corner-top" alt="Blomster" />
            </BrowserView>
            <MobileView>
                <img src={FlowersLeftCorner} className="Img-flower-left-corner-top" alt="Blomster" />
            </MobileView>
            <p className="Txt-title">Ønskeliste</p>
            <p className="Txt-info" style={{ zIndex: "5" }}>
                Vi har lagt inn en ønskeliste på Kitchn som du finner <a target="_blank" rel="noreferrer" href="https://www.kitchn.no/onskeliste/181949/">her</a>
            </p>
            <MobileView>
                <img src={FlowersLeftCorner} className="Img-flower-right-corner-bottom" alt="Blomster" />
            </MobileView>
        </Card>
    )
}

export default WishList;