import MainCard from './cards/MainCard';
import ToastmasterCard from './cards/ToastmasterCard';
import WishList from './cards/WishList';
import DressCode from './cards/DressCode';
import AllergiesCard from './cards/AllergiesCard';
import DirectionsCard from './cards/DirectionsCard';
import { isBrowser } from 'react-device-detect';
import './styles/App.css';
import './fonts/MrsEavesSmallCapsRoman.ttf'
import { Grid } from '@mantine/core';
import classes from './styles/Column.module.css'

function App() {
  return (
    <Grid
      style={{ backgroundColor: "#C4AE95", padding: "6px", height: "100vh" }}
      gutter={6}
    >
      <Grid.Col
        span={{ base: 12, md: 12, lg: 4 }}
        style={{ marginBottom: isBrowser ? "6px" : "0px" }}
        className={classes.SideRow}
        order={{ base: 2, xs: 2, sm: 2, lg: 1 }}
      >
        <ToastmasterCard />
        <AllergiesCard />
      </Grid.Col>

      <MainCard />

      <Grid.Col
        margin={0}
        span={{ base: 12, md: 12, lg: 4 }}
        className={classes.SideRow}
        order={{ base: 3 }}
      >
        <WishList />
        <DressCode />
      </Grid.Col>
      <Grid.Col span={{ base: 0, md: 2 }} order={{ base: 4, xs: 6, md: 4 }}></Grid.Col>
      <Grid.Col
        span={{ base: 12, md: 8, lg: 8 }}
        order={{ base: 5 }}
        align="center"
      >
        <DirectionsCard />
      </Grid.Col>
      <Grid.Col span={{ base: 0, md: 2 }} order={{ base: 6, xs: 7 }}></Grid.Col>
    </Grid>
  );
}

export default App;
