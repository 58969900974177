import FlowersLeftCorner from '../images/flowers_left_corner.png';
import { Card } from '@mantine/core';
import { BrowserView, MobileView, isBrowser } from 'react-device-detect';
import '../styles/App.css';

function DressCode() {
    return (
        <Card
            shadow="sm"
            radius="md"
            className="Side-card"
            style={{ marginTop: "6px", marginBottom: isBrowser ? "6px" : "0px" }}
            withBorder
        >
            <BrowserView>
                <img src={FlowersLeftCorner} className="Img-flower-right-corner-bottom" alt="Blomster" />
            </BrowserView>
            <MobileView>
                <img src={FlowersLeftCorner} className="Img-flower-right-corner-top" alt="Blomster" />
            </MobileView>
            <p className="Txt-title">Kleskode</p>
            <p className="Txt-info">Antrekkk: Pent <br />
                Men det viktigste for oss er at alle
                er komfortable :)
            </p>
            <MobileView>
                <img src={FlowersLeftCorner} className="Img-flower-left-corner-bottom" alt="Blomster" />
            </MobileView>
        </Card>
    )
}

export default DressCode;